<!-- 快乐8 -->
<template>
    <div class="">
       <klbheader></klbheader>
    </div> 
    <router-view />
    <smFooter></smFooter>
    <Footer></Footer>
</template>

<script>

import klbheader from '@/components/klb/klb-header.vue'
import smFooter from '@/components/common/exp.vue'
import  Footer from '@/components/Footer.vue'
export default {
name: '',
components: {
 klbheader,
 smFooter,
 Footer
},
data(){ // 数据源
    return{
    } 
},
mounted() {

},
computed:{
},
methods:{ // 函数体部分，js的主要逻辑控制               
},
props: {
}
}
</script>

<style scoped>
     /* @import './swiper.css'; */
</style>